import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import clsx from 'clsx'

import { FETCH_POLICIES_LIST } from 'constants/queries'
import { List, Loader } from 'components'
import { parseCurrency, parseAddress } from 'lib/helpers'
import { PolicyDocx } from './PolicyDocx'



const useStyles = makeStyles(theme => ({
  validChip: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
  },
  concludedChip: {
    backgroundColor: theme.palette.divider,
    color: theme.palette.icon,
  },
  lateChip: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  }
}))

const Actions = (policy) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Button
        color='primary'
        variant='contained'
      >
        {t('actions_see')}
      </Button>
      <Button
          color='primary'
          variant='contained'
        >
          {t('actions_delete')}
      </Button>
    </React.Fragment>
  )
}


const PolicyList = (props) => {
  const { data } = useQuery(FETCH_POLICIES_LIST, { 
    ...(props.query) ? {variables: {query: props.query}} : {},
    fetchPolicy: 'network-only'
  })
  const { t } = useTranslation()
  const classes = useStyles();


  const headCells = [
  {
      id: 'id',
      numeric: false,
      label: t('ID'),
      numeric: true,
      searchable: true,
      disablePadding: true,
      fn: p => parseInt(p.id),
      display: p => '#' + p.id
  }, {
      id: 'client',
      numeric: false,
      label: t('owner'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.user.fullName,
      display: p => p.user.fullName
    },{
      id: 'address',
      numeric: false,
      label: t('address'),
      searchable: true,
      disablePadding: true,
      fn: p => parseAddress(p.address),
      display: p => parseAddress(p.address, t),
    },{
      id: 'policyType',
      numeric: false,
      label: t('policyType'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.policyType,
      display: p => t(p.policyType),
    },{
      id: 'policyStatus',
      numeric: false,
      label: t('policyStatus'),
      searchable: true,
      disablePadding: true,
      fn: p => t(p.policyStatus),
      display: p => <Chip label={t(p.policyStatus)} className={clsx(p.policyStatus == 'pending' && classes.lateChip, p.policyStatus == 'expired' && classes.concludedChip, p.policyStatus == 'valid' && classes.validChip)}/>
    },{
      id: 'endDate',
      numeric: false,
      label: t('policyEndDate'),
      searchable: true,
      disablePadding: true,
      fn: p =>  moment(p.endDate).format('MMM DD, YYYY'),
      display: p => moment(p.endDate).format('MMM DD, YYYY'),
      sortFn: i => new Date(i.endDate)
    },{
      id: 'endDate',
      numeric: false,
      label: t('contractEndDate'),
      searchable: true,
      disablePadding: true,
      fn: p =>  moment(p.contract.endDate).format('MMM DD, YYYY'),
      display: p => moment(p.contract.endDate).format('MMM DD, YYYY'),
      sortFn: i => new Date(i.contract.endDate)
    }, {
      id: 'lastPaymentDate',
      numeric: false,
      label: t('lastPaymentDate'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.lastPayment ? moment(p.lastPayment.createdAt).format('MMM DD, YYYY') : 'N/A',
      display: p => p.lastPayment ? moment(p.lastPayment.createdAt).format('MMM DD, YYYY') : 'N/A',
      sortFn: p => new Date(p.lastPayment ? p.lastPayment.createdAt : 0)
    },
    {
      id: 'zipcode',
      numeric: false,
      label: t('zipcode'),
      numeric: false,
      searchable: true,
      disablePadding: false,
      fn: p => p.address.zipCode,
      display: p => p.address.zipCode
    }, {
      id: 'propertyUse',
      numeric: false,
      label: t('propertyUse'),
      numeric: false,
      searchable: true,
      disablePadding: false,
      fn: p => p.property.propertyUse,
      display: p => t(p.property.propertyUse)
    }, {

      id: 'actions',
      numeric: false,
      label: t('actions'),
      numeric: false,
      searchable: false,
      disablePadding: false,
      display: p => <Actions policy={p}/>
    }
  ];

  const Actions = ({policy}) => {
    const history = useHistory()
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
                <Button 
                    fullWidth 
                    className={classes.dropZoneButton} 
                    variant="contained" 
                    color="primary" 
                    onClick={() => history.push(`/document/tac/${policy.user.id}/${policy.property.id}`)}>
                              {t('cover_terms_and_conditions')}
                </Button>
            <Button
                onClick={() => history.push('/policyContract/' + policy.id )}
            >
            Contract Document
            </Button>
        </div>
    )
  }

  if(data) {
    return (
      <List
        label={t('policies')}
        subject={data.detailedPolicies}
        headCells={props.headCells ? props.headCells : headCells}
      />
    )
  }
  return <Loader/>
}
export default PolicyList
